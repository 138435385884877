import { MouseEvent, useState, JSX, Dispatch } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_USER, ADMINISTRATION, CONSO_ETAT_COMPTABLE, CONSOLIDATION, EVAL_EMPLOYEES, EVALUATION, modules } from "../../config/config.modules";
import AppBarButton from "../Tools/Action/Button/AppBarButton";
import { UsersInterface, useUserContext } from "./UserContext";
import AccessControl, { READ, SCOPE_MENU } from "../Tools/AccessControl/AccessControl";
import HeaderBar from "../Tools/Display/HeaderBar";
import { Typography } from "@mui/material";
import pageChangeProhibited from "../Tools/PageChangeProhibited/pageChangeProhibited";
import { AnyAction } from "@reduxjs/toolkit";
import ProfileIcon from "../Icons/ProfileIcon";
import PowerIcon from "../Icons/PowerIcon";
import { Tooltip } from "@mui/material";

const isDEMOEnabled: boolean = process.env.REACT_APP_DEMO === "1";

interface AppBarProps {
    isConnected: boolean;
    idModules: string;
    handleChangeConnected(newValue: boolean): any;
}

function DisplayHeaderBar ({ idModules }: any): JSX.Element {
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const [ defaultRouteAdmin, setDefaultRouteAdmin ] = useState<string>(ADMIN_USER);
    const [ defaultRouteEval, setDefaultRouteEval ] = useState<string>(EVAL_EMPLOYEES);
    const [ defaultRouteConso, setDefaultRouteConso ] = useState<string>(CONSO_ETAT_COMPTABLE);

    if (idModules === ADMINISTRATION) {
        dispatch({
            type: "SIZE_PERIMETER",
            longerPerimeter: true
        });

        return (
            <HeaderBar
                setNewRoute={setDefaultRouteAdmin}
                parent={ADMINISTRATION}
                actions={modules[1].menu}
                defaultRoute={defaultRouteAdmin}
            />
        );
    }

    if (idModules === EVALUATION) {
        dispatch({
            type: "SIZE_PERIMETER",
            longerPerimeter: true
        });

        return (
            <HeaderBar
                setNewRoute={setDefaultRouteEval}
                parent={EVALUATION}
                actions={modules[2].menu}
                defaultRoute={defaultRouteEval}
            />
        );
    }

    if (idModules === CONSOLIDATION) {
        dispatch({
            type: "SIZE_PERIMETER",
            longerPerimeter: true
        });

        return (
            <HeaderBar
                setNewRoute={setDefaultRouteConso}
                parent={CONSOLIDATION}
                actions={modules[4].menu}
                defaultRoute={defaultRouteConso}
            />
        );
    }

    dispatch({
        type: "SIZE_PERIMETER",
        longerPerimeter: false
    });

    return <></>;
}

export default function AppBar ({
    isConnected,
    idModules,
    handleChangeConnected
}: AppBarProps): JSX.Element {
    const navigate: Function = useNavigate();
    const user: UsersInterface | null = useUserContext();
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const isPageChangeAllowed: any = useSelector((state: any) => state.isPageChangeAllowed);

    return (
        <>
            <nav className={"bg-gradient-to-r from-BASE_PURPLE to-BASE_BLUE h-14 w-full"}>
                <div className={"px-2 h-full w-full sm:px-6 lg:px-4"}>
                    <div className={"flex h-full w-full items-center justify-between"}>
                        <div className={"flex cursor-pointer h-[70%] items-center"}>
                            <a
                                href={isConnected ? "../dashboard/home" : "../oauth/login"}
                                onClick={(event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>): void => {
                                    event.preventDefault();
                                    event.stopPropagation();

                                    const nav = (): void => {
                                        dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                        navigate(isConnected ? "../dashboard/home" : "../oauth/login");
                                    };

                                    if (isPageChangeAllowed) nav();
                                    else pageChangeProhibited(nav);
                                }}
                            >
                                <img
                                    src={require("../../Assets/Logo.png")}
                                    alt={"logo"}
                                />
                            </a>
                        </div>

                        <div className={"hidden md:block h-full"}>
                            <div className={"ml-10 flex font-worksans items-baseline space-x-4 h-full pt-3"}>
                                {isConnected && modules.map((item: any, index: number): JSX.Element => (
                                    <AccessControl
                                        key={`${index} - ${item.path} - ${item.name}`}
                                        section={item.rules}
                                        scope={SCOPE_MENU}
                                        right={READ}
                                    >
                                        <AppBarButton
                                            item={item}
                                            idModules={idModules}
                                            key={`${item.path} - ${index}`}
                                        />
                                    </AccessControl>
                                ))}
                            </div>
                        </div>

                        {isConnected
                            ? (
                                <div className={"hidden md:block"}>
                                    <div className={"ml-4 flex items-center md:ml-6"}>
                                        <div className={"relative ml-3"}>
                                            <div className={"flex flex-row space-x-4"}>
                                                <button
                                                    type={"button"}
                                                    className={"flex flex-row space-x-3 max-w-xs items-center rounded-full text-white text-sm text-start"}
                                                    id={"user-menu-button"}
                                                    aria-expanded={"false"}
                                                    aria-haspopup={"true"}
                                                    onClick={(): void => {
                                                        const nav = (): void => {
                                                            dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                                            navigate("profile");
                                                        };

                                                        if (isPageChangeAllowed) nav();
                                                        else pageChangeProhibited(nav);
                                                    }}
                                                >
                                                    <ProfileIcon />

                                                    <div className={"flex flex-col font-worksans"}>
                                                        <Typography
                                                            variant={"body2"}
                                                        >
                                                            {!!user ? user!.nom : ""} {!!user ? user!.prenom : ""}
                                                        </Typography>

                                                        <Typography
                                                            variant={"caption"}
                                                        >
                                                            {isDEMOEnabled ? "Secoia " : !!user && !!user.client ? user!.client.nom : ""} | {!!user && !!user.client ? user!.permissions.profil : ""}
                                                        </Typography>
                                                    </div>
                                                </button>

                                                <Tooltip title={"Se déconnecter"}>
                                                    <button
                                                        onClick={async (): Promise<void> => {
                                                            const nav = (): void => {
                                                                dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                                                handleChangeConnected(false);
                                                                navigate("disconnect");
                                                            };

                                                            if (isPageChangeAllowed) nav();
                                                            else pageChangeProhibited(nav);
                                                        }}
                                                    >
                                                        <PowerIcon />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : (
                                <div className={"hidden md:block"}>
                                    <ul className={"flex flex-row font-medium font-worksans text-sm text-white space-x-5"}>
                                        <li
                                            className={"hover:underline cursor-pointer"}
                                            onClick={(): void => {
                                                const nav = (): void => {
                                                    dispatch({ type: "ALLOW_PAGE_CHANGE" });
                                                    navigate("../oauth/login");
                                                };

                                                if (isPageChangeAllowed) nav();
                                                else pageChangeProhibited(nav);
                                            }}
                                        >
                                            Connexion
                                        </li>
                                    </ul>
                                </div>
                            )}
                    </div>
                </div>
            </nav>

            <DisplayHeaderBar idModules={idModules} />
        </>
    );
}
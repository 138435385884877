import * as ExcelJS from 'exceljs';
import getTextWidth from "../../Utils/getTextWidth";
import {IsText} from "./ExceptionList";

function countDecimalDigits(cellValue: number) {
    const stringNumber = cellValue.toString();
    const decimalPart = stringNumber.split('.')[1];
    if (decimalPart) {
        return decimalPart.length;
    } else {
        return 0;
    }
}

export default async function exportXLSX(data: any, fileName: string , library?: boolean, libraryPerimeter?: any) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(fileName, { views: [{ state: "frozen", ySplit: 1 }] });

    // Assuming your headers are stored in an array
    const headerKeys = Object.keys(
        !!data[0].row
            ? data[0].row
            : data[0]
    );

    // Create an object to store column format information
    const columnFormats: { [key: number]: string } = {};

    // Add header row and apply styling, including borders
    const headerRow = worksheet.addRow(headerKeys);
    headerRow.eachCell((cell) => {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4A5BCB' },
        };
        cell.font = {
            color: { argb: 'FFFFFF' },
            bold: true,
        };

        // Set border style for the cell
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
        };
        cell.alignment = { wrapText: true };
    });

    // Automatically adjust column widths based on header cell widths
    headerKeys.forEach((header, index) => {
        const column = worksheet.getColumn(index + 1); // Columns are 1-based
        if (header.startsWith('Date')) {
            // Store the format for the entire column
            columnFormats[index + 1] = 'DD/MM/YYYY';
        }
        if (IsText.some(Name => header.startsWith(Name))) {
            columnFormats[index + 1] = '@';
        }

        const __font = headerRow.getCell(index + 1).font;
        const font = `${__font.bold ? 'bold ' : ''}${__font.italic ? 'italic ' : ''}${__font.size}px ${__font.name || 'Arial'}`;
        column.width = Math.min(30, Math.max(10, getTextWidth(header, font) + 2)); // Set a min and max width
    });

    // Add data rows without specifying cell type
    data.forEach((rowData: any): void => {
        const isTotalRow: boolean = rowData.type === 1;
        const row           = worksheet.addRow(Object.values(!!rowData.row
            ? rowData.row
            : rowData
        ));

        row.eachCell((cell, cellNumber) => {
            cell.alignment = { horizontal: 'left' };

            if (columnFormats[cellNumber]) {
                cell.numFmt = columnFormats[cellNumber];
            } else if(cellNumber !== 1) {
                const cellValue = parseFloat(cell.text);

                if (!isNaN(cellValue)){
                    const numberDecimal = countDecimalDigits(cellValue);
                    if (numberDecimal === 0)
                        cell.numFmt = "#,##0";
                    if (numberDecimal === 2)
                        cell.numFmt = "#,##0.00";
                    if (numberDecimal === 4)
                        cell.numFmt = "#,##0.0000";
                }
            }

            if (isTotalRow && !!rowData.row) {
                cell.font = {
                    bold: true
                };

                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'dddddd' },
                };
            }

            // Set border style for the cell
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });
    });

    if (library) {
        const worksheetIDCC = workbook.addWorksheet('code IDCC');

        const headerKeysIDCC = Object.values(
            {
                'code_idcc': 'Code IDCC',
                'convention': 'Convention',
            });
        // Create an object to store column format information
        // Add header row and apply styling, including borders
        const headerRowIDCC = worksheetIDCC.addRow(headerKeysIDCC);
        headerRowIDCC.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '4A5BCB'},
            };
            cell.font = {
                color: {argb: 'FFFFFF'},
                bold: true,
            };

            // Set border style for the cell
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'},
            };
            cell.alignment = {wrapText: true};
        });

        headerKeysIDCC.forEach((header, index) => {
            const column = worksheetIDCC.getColumn(index + 1); // Columns are 1-based
            if (header.startsWith('Date')) {
                // Store the format for the entire column
                columnFormats[index + 1] = 'DD/MM/YYYY';
            }
            if (IsText.some(Name => header.startsWith(Name))) {
                columnFormats[index + 1] = '@';
            }

            const __font = headerRowIDCC.getCell(index + 1).font;
            const font = `${__font.bold ? 'bold ' : ''}${__font.italic ? 'italic ' : ''}${__font.size}px ${__font.name || 'Arial'}`;
            column.width = Math.min(30, Math.max(10, getTextWidth(header, font) + 2)); // Set a min and max width
        });

        // Add data rows without specifying cell type
        libraryPerimeter.convention.forEach((rowData: any) => {
            const value = {...rowData};
            delete value.value;
            delete value.label;

            const row = worksheetIDCC.addRow(Object.values(value));
            row.eachCell((cell) => {
                cell.alignment = {horizontal: 'left'};
                // Set border style for the cell
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'},
                };
            });
        });

        // 4 worksheet

        const worksheetCSP = workbook.addWorksheet('Périmètre');

        const headerKeysCSP = Object.values(
            {
                'code_idcc': "Code",
                'csp': 'Libellé',
            });
        // Create an object to store column format information
        // Add header row and apply styling, including borders
        const headerRowCSP = worksheetCSP.addRow(headerKeysCSP);
        headerRowCSP.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '4A5BCB'},
            };
            cell.font = {
                color: {argb: 'FFFFFF'},
                bold: true,
            };

            // Set border style for the cell
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'},
            };
            cell.alignment = {wrapText: true};
        });

        headerKeysCSP.forEach((header, index) => {
            const column = worksheetCSP.getColumn(index + 1); // Columns are 1-based
            if (header.startsWith('Date')) {
                // Store the format for the entire column
                columnFormats[index + 1] = 'DD/MM/YYYY';
            }
            if (IsText.some(Name => header.startsWith(Name))) {
                columnFormats[index + 1] = '@';
            }

            const __font = headerRowCSP.getCell(index + 1).font;
            const font = `${__font.bold ? 'bold ' : ''}${__font.italic ? 'italic ' : ''}${__font.size}px ${__font.name || 'Arial'}`;
            column.width = Math.min(30, Math.max(10, getTextWidth(header, font) + 2)); // Set a min and max width
        });

        // Add data rows without specifying cell type
        libraryPerimeter.csp.forEach((rowData: any) => {
            const value = {...rowData};
            delete value.id;
            delete value.value;
            delete value.label;

            const row = worksheetCSP.addRow(Object.values(value));
            row.eachCell((cell) => {
                cell.alignment = {horizontal: 'left'};

                // Set border style for the cell
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'},
                };
            });
        });

        const worksheetSociety = workbook.addWorksheet('Société');

        const headerKeysSociety = Object.values(
            {
                'code_osmose': 'Code osmose',
                'nom': 'Nom de la société'
            });
        // Create an object to store column format information
        // Add header row and apply styling, including borders
        const headerRowSociety = worksheetSociety.addRow(headerKeysSociety);
        headerRowSociety.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: '4A5BCB'},
            };
            cell.font = {
                color: {argb: 'FFFFFF'},
                bold: true,
            };

            // Set border style for the cell
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'},
            };
            cell.alignment = {wrapText: true};
        });

        headerKeysSociety.forEach((header, index) => {
            const column = worksheetSociety.getColumn(index + 1); // Columns are 1-based
            if (header.startsWith('Date')) {
                // Store the format for the entire column
                columnFormats[index + 1] = 'DD/MM/YYYY';
            }
            if (IsText.some(Name => header.startsWith(Name))) {
                columnFormats[index + 1] = '@';
            }

            const __font = headerRowSociety.getCell(index + 1).font;
            const font = `${__font.bold ? 'bold ' : ''}${__font.italic ? 'italic ' : ''}${__font.size}px ${__font.name || 'Arial'}`;
            column.width = Math.min(30, Math.max(10, getTextWidth(header, font) + 2)); // Set a min and max width
        });

        // Add data rows without specifying cell type
        libraryPerimeter.societe.forEach((rowData: any) => {
            const value = {...rowData};
            delete value.id;
            delete value.value;
            delete value.label;
            delete value.valeur_niveau;
            const row = worksheetSociety.addRow(Object.values(value).reverse());
            row.eachCell((cell) => {
                cell.alignment = {horizontal: 'left'};

                // Set border style for the cell
                cell.border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'},
                };
            });
        });
    }

    // Create a blob from the workbook
    const blob = await workbook.xlsx.writeBuffer();

    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

    // Create a download link and simulate a click
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${fileName}.xlsx`;
    link.click();

    // Clean up the blob URL
    URL.revokeObjectURL(blobUrl);
}

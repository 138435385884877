import React, {Dispatch, useCallback, useEffect, useState, JSX, useContext} from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FormatPerimeter, Society } from "./Evaluation/Library/ToolsLibrary/PerimeterSelection";
import { DEFAULT_PERIMETER } from "../../constants";
import { useHandleError } from "../Tools/HandleError/HandleError";
import * as request from "../Tools/Utils/APIRequests/request";
import MultiAutocompleteFilter from "../Tools/Action/Inputs/MultiAutocompleteFilter";
import { InitialState } from "../Tools/Store/store";
import { AnyAction } from "@reduxjs/toolkit";
import UserPerimeterContext, { UserPerimeterContextProps } from "../Tools/context/UserPerimeterContext";

interface FilterBarProps {
    isConnected: boolean;
}

export type RangePole = {
    value: number;
    name: string;
    code_societe: string;
    divisions: RangeDivision[];
}

export type RangeDivision = {
    value: number;
    name: string;
    code_societe: string;
    directions_operationnelles: RangeDirection[];
}

export type RangeDirection = {
    value: number;
    name: string;
    code_societe: string;
    entites_juridiques: RangeLegalEntity[];
}

export type RangeLegalEntity = {
    value: number;
    name: string;
    code_societe: string;
}

export default function FilterBar ({ isConnected }: FilterBarProps): JSX.Element | null {
    const [ perimeter, _setPerimeter ] = useState<FormatPerimeter>(JSON.parse(localStorage.getItem("PERIMETER") ?? "null")?.perimeter ?? DEFAULT_PERIMETER);
    const [ filteredPerimeter, setFilteredPerimeter ] = useState<FormatPerimeter>(perimeter);
    const [ legalsEntities, setLegalsEntities ] = useState<any[]>([]);
    const [ operationnalsDirections, setOperationnalsDirections ] = useState<any[]>([]);
    const [ divisions, setDivisions ] = useState<any[]>([]);
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const isLoading: boolean = useSelector((state: InitialState) => state.reloadPerimeter);
    const perimeterContext: UserPerimeterContextProps | null = useContext(UserPerimeterContext);
    const handleError: (err: any, callback: any, message?: string, needRedirect?: boolean) => void = useHandleError();

    useEffect((): void => {
        if (!isLoading) return;

        (async (): Promise<void> => {
            const req: request.Request = await request.get(`${process.env.REACT_APP_LEA_API}/user/me/perimetre`);

            if (req.isSuccessful) {
                const response: any = req.response.data.data;
                const pole: any = response.poles.map((item: RangePole) => (
                    {
                        value: item.value,
                        id: item.value,
                        code: item.code_societe,
                        label: `${item.code_societe} - ${item.name}`,
                        nom: item.name
                    }
                ));
                const division: any = response.poles.flatMap((pole: RangePole) => (
                    pole.divisions.map((item: RangeDivision) => ({
                        value: item.value,
                        id: item.value,
                        code: item.code_societe,
                        label: `${item.code_societe} - ${item.name}`,
                        nom: item.name
                    }))
                ));
                const direction_operationnelle: any = response.poles.flatMap((pole: RangePole) => (
                    pole.divisions.flatMap((division: RangeDivision) =>
                        division.directions_operationnelles.map((item: RangeDirection) => ({
                            value: item.value,
                            id: item.value,
                            code: item.code_societe,
                            label: `${item.code_societe} - ${item.name}`,
                            nom: item.name
                        }))
                    )
                ));
                const entite_juridique: any = response.poles.flatMap((pole: RangePole) => (
                    pole.divisions.flatMap((division: RangeDivision) =>
                        division.directions_operationnelles.flatMap((direction: RangeDirection) =>
                            direction.entites_juridiques.map((item: RangeLegalEntity) => ({
                                value: item.value,
                                id: item.value,
                                code: item.code_societe,
                                label: `${item.code_societe} - ${item.name}`,
                                nom: item.name
                            }))
                        )
                    )
                ));

                setFilteredPerimeter((current: FormatPerimeter): FormatPerimeter => {
                    localStorage.setItem("PERIMETER", JSON.stringify({
                        perimeter: {
                            ...current,
                            pole: pole,
                            division: division,
                            direction_operationnelle: direction_operationnelle,
                            entite_juridique: entite_juridique
                        },
                        rawPerimeter: {
                            pole: pole.map((item: RangePole) => item.value),
                            division: division.map((item: RangeDivision) => item.value),
                            direction_operationnelle: direction_operationnelle.map((item: RangeDirection) => item.value),
                            entite_juridique: entite_juridique.map((item: RangeLegalEntity) => item.value),
                            id_import: current.id_import?.value ?? null,
                            date_import: current.date_import
                        }
                    }));

                    return {
                        ...current,
                        pole: pole,
                        division: division,
                        direction_operationnelle: direction_operationnelle,
                        entite_juridique: entite_juridique
                    };
                });

                dispatch({ type: "RESET_PERIMETER" });
            } else if (req.isSessionExpired) {
                handleError(
                    req.error,
                    (): void => {},
                    req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE,
                    true
                );

                dispatch({ type: "RESET_PERIMETER" });
            } else if (req.error.response) {
                handleError(
                    req.error,
                    (): void => {},
                    req.error.response.data.message
                );

                dispatch({ type: "RESET_PERIMETER" });
            }
        })();
    }, [
        dispatch,
        handleError,
        isLoading
    ]);

    useEffect((): void => {
        // Already loaded variable used to filter perimeter
        if (divisions.length > 0 && operationnalsDirections.length > 0 && legalsEntities.length > 0) return;

        // Try to get perimeter to filter by context or browser local storage in case of page reload
        let userPerimeter: any = perimeterContext?.userPerimeter ?? (JSON.parse(localStorage.getItem('userPerimeterContext') ?? "null"));
        const _divisions: any[] = [];
        const _operationnalsDirections: any[] = [];
        const _legalsEntities: any[] = [];

        // This part is for avoid crash when the user reload the page or if the userContext loose the data defined at the user login
        if (!userPerimeter) {
            // If the user active perimeter could not be found, get it directly by the URL
            const fetchUserPerimeter: Function = async(): Promise<void> => {
                const req: request.Request = await request.get(`${process.env.REACT_APP_LEA_API}/user/me/perimetre`);

                if (req.isSuccessful) {
                    const response: any = req.response.data.data;

                    localStorage.setItem("userPerimeterContext", JSON.stringify(response.poles));
                    perimeterContext?.setUserPerimeter(response.poles);
                    userPerimeter = response.poles;
                } else {
                    handleError(
                        req.error,
                        (): void => {},
                        req.error.response.data.message,
                        req.isSessionExpired
                    );
                }
            };

            fetchUserPerimeter();
        }

        // Set variables used to filter active perimeter
        userPerimeter?.forEach((pole: RangePole): void => {
            pole?.divisions?.forEach((division: RangeDivision): void => {
                _divisions.push({
                    id: division.value,
                    label: `${division.code_societe} - ${division.name}`,
                    nom: division.name,
                    value: division.value,
                    code: division.code_societe,
                    pole: {
                        id: pole.value,
                        label: `${pole.code_societe} - ${pole.name}`,
                        nom: pole.name,
                        value: pole.value,
                        code: pole.code_societe,
                    }
                });

                division?.directions_operationnelles?.forEach((direction: RangeDirection): void => {
                    _operationnalsDirections.push({
                        id: direction.value,
                        label: `${direction.code_societe} - ${direction.name}`,
                        nom: direction.name,
                        value: direction.value,
                        code: direction.code_societe,
                        division: {
                            id: division.value,
                            label: `${division.code_societe} - ${division.name}`,
                            nom: division.name,
                            value: division.value,
                            code: division.code_societe,
                            pole: {
                                id: pole.value,
                                label: `${pole.code_societe} - ${pole.name}`,
                                nom: pole.name,
                                value: pole.value,
                                code: pole.code_societe,
                            }
                        }
                    });

                    direction?.entites_juridiques?.forEach((entity: RangeLegalEntity): void => {
                        _legalsEntities.push({
                            id: entity.value,
                            label: `${entity.code_societe} - ${entity.name}`,
                            nom: entity.name,
                            value: entity.value,
                            code: entity.code_societe,
                            direction: {
                                id: direction.value,
                                label: `${direction.code_societe} - ${direction.name}`,
                                nom: direction.name,
                                value: direction.value,
                                code: direction.code_societe,
                                division: {
                                    id: division.value,
                                    label: `${division.code_societe} - ${division.name}`,
                                    nom: division.name,
                                    value: division.value,
                                    code: division.code_societe,
                                    pole: {
                                        id: pole.value,
                                        label: `${pole.code_societe} - ${pole.name}`,
                                        nom: pole.name,
                                        value: pole.value,
                                        code: pole.code_societe,
                                    }
                                }
                            }
                        });
                    });
                });
            });
        });

        // Store result in useState variable for avoid relaunch the entire function when the variables have been already defined
        setDivisions(_divisions);
        setOperationnalsDirections(_operationnalsDirections);
        setLegalsEntities(_legalsEntities);
    }, [ perimeterContext ]);

    const recursiveFilterPerimeter: Function = useCallback(<T extends keyof FormatPerimeter>(
        option: Society,
        key: T,
        check: boolean,
        magicSelect: boolean | null,
        currentPerimeter: FormatPerimeter
    ): any =>  {
        if ((key === "entite_juridique" && !check) || (key === "pole" && check)) return currentPerimeter;
        else if (magicSelect !== null) {
            const perimeterHierarchy: string[] = Object.keys(currentPerimeter);

            if (magicSelect) {
                perimeterHierarchy
                    .splice(0, perimeterHierarchy.indexOf(key))
                    .map((x: string) => currentPerimeter[x as T] = perimeter[x as T]);
            } else {
                perimeterHierarchy
                    .splice(perimeterHierarchy.indexOf(key), perimeterHierarchy.length)
                    .map((x: string) => (currentPerimeter[x as T] as Society[]) = []);
            }

            return currentPerimeter;
        }

        if (!check) {
            const filterOptions: Function = (
                list: any[],
                value: number
            ) => list?.filter((x: any): boolean => x?.value !== value);

            perimeterContext?.userPerimeter?.forEach((pole: RangePole): void => {
                if (pole.name === option?.nom) {
                    pole?.divisions?.forEach((division: RangeDivision): void => {
                        // Filter Division
                        currentPerimeter.division = filterOptions(
                            currentPerimeter.division,
                            division.value
                        );

                        division?.directions_operationnelles?.forEach((direction: RangeDirection): void => {
                            // Filter Direction
                            currentPerimeter.direction_operationnelle = filterOptions(
                                currentPerimeter.direction_operationnelle,
                                direction.value
                            );

                            direction?.entites_juridiques?.forEach((entity: RangeLegalEntity): void => {
                                // Filter Entities
                                currentPerimeter.entite_juridique = filterOptions(
                                    currentPerimeter.entite_juridique,
                                    entity.value
                                );
                            });
                        });
                    });
                } else {
                    pole?.divisions?.forEach((division: RangeDivision): void => {
                        if (division.name === option?.nom) {
                            division?.directions_operationnelles?.forEach((direction: RangeDirection): void => {
                                // Filter Direction
                                currentPerimeter.direction_operationnelle = filterOptions(
                                    currentPerimeter.direction_operationnelle,
                                    direction.value
                                );

                                direction?.entites_juridiques?.forEach((entity: RangeLegalEntity): void => {
                                    // Filter Entities
                                    currentPerimeter.entite_juridique = filterOptions(
                                        currentPerimeter.entite_juridique,
                                        entity.value
                                    );
                                });
                            });
                        } else {
                            division?.directions_operationnelles?.forEach((direction: RangeDirection): void => {
                                if (direction.name === option?.nom) {
                                    direction?.entites_juridiques?.forEach((entity: RangeLegalEntity): void => {
                                        // Filter Entities
                                        currentPerimeter.entite_juridique = filterOptions(
                                            currentPerimeter.entite_juridique,
                                            entity.value
                                        );
                                    });
                                }
                            });
                        }
                    });
                }
            });
        } else {
            const addIfNotExists: Function = (list: any[], item: any): void => {
                if (!list.some((x): boolean => x.value === item.value)) list.push(item);
            };

            if (key === "entite_juridique") {
                const element = legalsEntities.find((x: any): any => x.value === option.value);
                const dirOp: Society = {
                    id: element.direction.value,
                    label: `${element.direction.code} - ${element.direction.nom}`,
                    nom: element.direction.nom,
                    value: element.direction.value,
                    code_societe: element.direction.code,
                };
                const division: Society = {
                    id: element.direction.division.value,
                    label: `${element.direction.division.code} - ${element.direction.division.nom}`,
                    nom: element.direction.division.nom,
                    value: element.direction.division.value,
                    code_societe: element.direction.division.code,
                };
                const pole: Society = {
                    id: element.direction.division.pole.value,
                    label: `${element.direction.division.pole.code} - ${element.direction.division.pole.nom}`,
                    nom: element.direction.division.pole.nom,
                    value: element.direction.division.pole.value,
                    code_societe: element.direction.division.pole.code,
                };

                addIfNotExists(currentPerimeter.direction_operationnelle, dirOp);
                addIfNotExists(currentPerimeter.division, division);
                addIfNotExists(currentPerimeter.pole, pole);
            } else {
                if (key === "direction_operationnelle") {
                    const element = operationnalsDirections.find((x: any): any => x.value === option.value);
                    const division: Society = {
                        id: element.division.value,
                        label: `${element.division.code} - ${element.division.nom}`,
                        nom: element.division.nom,
                        value: element.division.value,
                        code_societe: element.division.code,
                    };
                    const pole: Society = {
                        id: element.division.pole.value,
                        label: `${element.division.pole.code} - ${element.division.pole.nom}`,
                        nom: element.division.pole.nom,
                        value: element.division.pole.value,
                        code_societe: element.division.pole.code,
                    };

                    addIfNotExists(currentPerimeter.division, division);
                    addIfNotExists(currentPerimeter.pole, pole);
                } else {
                    const element = divisions.find((x: any): any => x.value === option.value);
                    const pole: Society = {
                        id: element.pole.value,
                        label: `${element.pole.code} - ${element.pole.nom}`,
                        nom: element.pole.nom,
                        value: element.pole.value,
                        code_societe: element.pole.code,
                    };

                    addIfNotExists(currentPerimeter.pole, pole);
                }
            }
        }

        return currentPerimeter;
    }, [
        perimeterContext,
        divisions,
        operationnalsDirections,
        legalsEntities
    ]);

    /**
     * @params {string} key - Key of FormatPerimeter object
     * @params {FormatPerimeter} value - FormatPerimeter object
     * @params {boolean | null} globalActionSelect - Variable provide a way to identify when inputs "unselect all" & "select all" are clicked with a boolean value, otherwise is null
     * @return void
     */
    const handleChangePerimeter: Function = useCallback(<T extends keyof FormatPerimeter>(key: T, value: FormatPerimeter[T], globalActionSelect: boolean | null = null): void => {
        setFilteredPerimeter((current: FormatPerimeter): FormatPerimeter => {
            const unselected: Society             = (current[key] as Society[]).filter((x: Society) => !(value as Society[]).includes(x))[0];
            const selected: Society               = (value as Society[])?.filter((x: Society) => !(current[key] as Society[]).includes(x))[0];
            const formatPerPole : number[]        = [];
            const formatPerDivision: number[]     = [];
            const formatPerDirOp : number[]       = [];
            const formatPerEntity : number[]      = [];
            const __perimeter: any                = {
                ...current,
                [key]: value
            };
            const recursiveFilteredPerimeter: any = recursiveFilterPerimeter(unselected ?? selected, key, unselected === undefined, globalActionSelect, {
                pole                     : __perimeter?.pole,
                division                 : __perimeter?.division,
                direction_operationnelle : __perimeter?.direction_operationnelle,
                entite_juridique         : __perimeter?.entite_juridique
            });
            const newPerimeter: FormatPerimeter   = {
                ...perimeter,
                pole: recursiveFilteredPerimeter.pole,
                division: recursiveFilteredPerimeter.division,
                direction_operationnelle: recursiveFilteredPerimeter.direction_operationnelle,
                entite_juridique: recursiveFilteredPerimeter.entite_juridique,
            }

            newPerimeter.pole?.forEach((item: Society) => formatPerPole.push(item.value));
            newPerimeter.division?.forEach((item: Society) => formatPerDivision.push(item.value));
            newPerimeter.direction_operationnelle?.forEach((item: Society) => formatPerDirOp.push(item.value));
            newPerimeter.entite_juridique?.forEach((item: Society) => formatPerEntity.push(item.value));
            localStorage.setItem("PERIMETER", JSON.stringify({
                perimeter: { ...newPerimeter },
                rawPerimeter: {
                    pole: formatPerPole,
                    division: formatPerDivision,
                    direction_operationnelle: formatPerDirOp,
                    entite_juridique: formatPerEntity,
                    id_import: newPerimeter.id_import?.value ?? null,
                    date_import: newPerimeter.date_import || null
                }
            }));

            return newPerimeter;
        });

        dispatch({ type: "LOAD_PERIMETER" });
    }, [
        divisions,
        legalsEntities,
        operationnalsDirections
    ]);

    const handleBlurPermimeter: () => void = useCallback((): void => {
        dispatch({ type: "LOAD_PERIMETER" });
    }, [ dispatch ]);

    if (!isConnected || localStorage.getItem("JWT") == null) return null;

    return (
        <div className={"w-full bg-MAIN_COLOR"}>
            <div
                id={"active_perimeter"}
                className={"flex items-center space-x-3 px-10 h-full w-full bg-MAIN_COLOR gap-2"}
            >
                <div className={"text-[12px] text-left text-blue-800 font-semibold mb-2"}>
                    <Typography
                        variant={"body1"}
                        className={"text-start pt-5 text-neutral-700 font-worksans whitespace-nowrap font-normal"}
                    >
                        Périmètre actif
                    </Typography>

                    <button
                        onClick={(): void => {
                            dispatch({ type: "RESET_PERIMETER" });
                        }}
                    >
                        <span>
                            Réinitialiser
                        </span>
                    </button>
                </div>

                {isLoading
                    ? <div className={"pt-6 w-full items-center"}>
                        <CircularProgress size={25} />
                    </div>
                    : <>
                        <MultiAutocompleteFilter
                            bold
                            labelCodeSociete
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/pole`}
                            handleChange={(value: Society[], globalActionSelect: boolean | null) => handleChangePerimeter("pole", value, globalActionSelect)}
                            value={filteredPerimeter.pole}
                            label={"Pôle"}
                            perimeter={perimeter}
                            handleBlurPermimeter={handleBlurPermimeter}
                        />

                        <MultiAutocompleteFilter
                            bold
                            labelCodeSociete
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/division`}
                            handleChange={(value: Society[], globalActionSelect: boolean | null) => handleChangePerimeter("division", value, globalActionSelect)}
                            value={filteredPerimeter.division}
                            label={"Division"}
                            perimeter={perimeter}
                            handleBlurPermimeter={handleBlurPermimeter}
                        />

                        <MultiAutocompleteFilter
                            bold
                            labelCodeSociete
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/direction`}
                            handleChange={(value: Society[], globalActionSelect: boolean | null) => handleChangePerimeter("direction_operationnelle", value, globalActionSelect)}
                            value={filteredPerimeter.direction_operationnelle}
                            label={"Direction opérationnelle"}
                            perimeter={perimeter}
                            handleBlurPermimeter={handleBlurPermimeter}
                        />

                        <MultiAutocompleteFilter
                            bold
                            labelCodeSociete
                            url={`${process.env.REACT_APP_LEA_API}/admin/societies/legal`}
                            handleChange={(value: Society[], globalActionSelect: boolean | null) => handleChangePerimeter("entite_juridique", value, globalActionSelect)}
                            value={filteredPerimeter.entite_juridique}
                            label={"Entité juridique"}
                            perimeter={perimeter}
                            handleBlurPermimeter={handleBlurPermimeter}
                        />
                    </>
                }
            </div>
        </div>
    );
}
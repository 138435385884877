import { Button, debounce, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, TextField, Tooltip } from "@mui/material";
import React, { KeyboardEvent, useCallback, useEffect, useState, useContext } from "react";
import DescriptionIcon from '@mui/icons-material/Description';
import AlertsSnackBar from "../Feedback/AlertsSnackBar";
import DialogHelp from "./Dialog/DialogHelp";
import DialogFilename from "./Dialog/DialogFilename";
import {GridRowId} from "@mui/x-data-grid";
import AccessControl, { ADD, ARCHIVING, DELETE, EXPORT, SCOPE_TABLE, SCOPE_WINDOW, UPDATE } from "../AccessControl/AccessControl";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHandleSuccess } from "../HandleSuccess/HandleSuccess";
import DialogError from "./TableRender/Toolbar/DialogError";
import TableSearchBarContext from "../context/TableSearchBarContext";
import SearchIcon from "../../Icons/SearchIcon";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import CopyIcon from "../../Icons/CopyIcon";
import CreateIcon from "../../Icons/CreateIcon";
import DeleteIcon from "../../Icons/DeleteIcon";
import RestoreIcon from "../../Icons/RestoreIcon";
import ReferenceIcon from "../../Icons/ReferenceIcon";
import LockIcon from "../../Icons/LockIcon";
import EditIcon from "../../Icons/EditIcon";
import ExportIcon from "../../Icons/ExportIcon";

export default function ToolsBarTable({
    data,
    columns,
    select,
    origin = 'document',
    handleOpenCreate = undefined,
    handleOpenDelete = undefined,
    disableSearch = false,
    isDeleteDisable = false,
    isUpdateDisable = false,
    isRestoreDisable = false,
    handleOpenUpdate = undefined,
    handleFilterSearch = undefined,
    handleOpenRestore = undefined,
    handleOpenLock = undefined,
    isLockDisable = false,
    handleDuplicate = undefined,
    rolesSection,
    handleChangeFilter,
    exportUrl = null,
    filter = null,
    resetSearch = false,
    isReferenceDisable = false,
    handleOpenReference = undefined,
}: any) {
    const [anchorElExport, setAnchorElExport] = useState<HTMLButtonElement | null>(null);
    const [anchorElSearch, setAnchorElSearch] = useState<HTMLButtonElement | null>(null);
    const [alertCopy, setAlertCopy] = useState<boolean>(false);
    const searchBarContext = useContext(TableSearchBarContext);
    const [openDialogHelp, setOpenDialogHelp] = useState({title: "", content: "", open: false});
    const [isLoadingDuplicate, setIsLoadingDuplicate] = useState<boolean>(false);
    const [openDialogDuplication, setOpenDialogDuplication] = useState<boolean>(false);
    const [nameDuplication, setNameDuplication] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [openDialogFileName, setOpenDialogFileName] = useState<{
        open: boolean,
        type: "PDF" | "EXCEL" | "PDF_ALL" | "EXCEL_ALL"
    }>({
        open: false,
        type: "PDF"
    });
    const handleSuccess = useHandleSuccess();

    // ne pas rajouter de dépendance à ce useEffect comme searchBarContext si la recherche ce reset à chaque fois
    // eslint-disable-next-line
    useEffect(() => searchBarContext?.setSearch(""), [resetSearch]);

    const handleOpenExport = (event: any) => {
        setAnchorElExport(event.currentTarget);
    };

    const handleOpenDuplicate = () => {
        setOpenDialogDuplication(true);
    };

    const handleCloseDuplication = () => {
        setNameDuplication("");
        setOpenDialogDuplication(false);
    };

    const handleOpenSearch = (event: any) => {
        setAnchorElSearch(event.currentTarget);
    };

    async function handleSubmit(event: any) {
        event.preventDefault();
        setIsLoadingDuplicate(true);
        await handleDuplicate(nameDuplication);
        handleCloseDuplication();
        setIsLoadingDuplicate(false);
    }

    const handleCloseExport = () => {
        setAnchorElExport(null);
    };

    const handleCloseSearch = () => {
        setAnchorElSearch(null);
    };

    function getColumnsWithData(data: any[], columns: any[]): string[] {
        const columnsWithData = columns.filter((col: any) => {
            return data.some((row: any) => {
                const value = col.exportValue ? col.exportValue(row) : row[col.field];
                return value !== null && value !== undefined && value !== "";
            });
        });
        return columnsWithData.map((col: any) => col.headerName);
    }

    function formatDataWithoutSelect(newData: any, isExcel: boolean) {
        const validColumns = getColumnsWithData(newData, columns);

        return newData.map((row: any) => {
            const newRow: any = {};

            columns.forEach((col: any) => {
                if (validColumns.includes(col.headerName)) {
                    let value;
                    if (isExcel && col.exportExcelValue) {
                        value = col.exportExcelValue(row);
                    } else {
                        value = col.exportValue ? col.exportValue(row) : row[col.field];
                    }

                    newRow[col.headerName] = value !== null && value !== undefined ? value : "";
                }
            });

            return newRow;
        });
    }

    function formatData(isExcel: boolean) {
        const validColumns = getColumnsWithData(data, columns);
        const newFormat = select.map((selectedId: GridRowId) => {
            const row = data.find((rowData: any) => rowData.fake_id === selectedId || rowData.id === selectedId);
            if (row) {
                return columns.reduce((result: any, col: any) => {
                    if (validColumns.includes(col.headerName)) {
                        let value;
                        if (isExcel && col.exportExcelValue) {
                            value = col.exportExcelValue(row);
                        } else {
                            value = col.exportValue ? col.exportValue(row) : row[col.field];
                        }

                        result[col.headerName] = value !== null && value !== undefined ? value : "";
                    }
                    return result;
                }, {});
            }

            return null;
        });
        return newFormat.filter((item: any) => item !== null);
    }

    function formatDataSearch() {
        return data.map((row: any) => {
            const newRow: any = {};

            columns.forEach((col: any) => {
                if (!col.isExportable) {
                    newRow[col.headerName] = col.exportValue
                        ? col.exportValue(row)
                        : row[col.field]
                    ;
                }
            });

            return newRow;
        });
    }

    const debouncedHandleSearch = useCallback(debounce((value: string) => {
            const indexSelected: number[] = [];
            const dataSelected: any[] = [];

            if (value === "") {
                handleFilterSearch(data);

                return;
            }

            const formattedData: any[] = formatDataSearch();

            formattedData.forEach((elem: any, index: number) => {
                for (const key in elem) {
                    if (!!elem[key] && !!elem[key].includes && elem[key].toLowerCase().includes(value.toLowerCase())) {
                        indexSelected.push(index);

                        break;
                    }
                }
            });

            indexSelected.forEach((item: any) => {
                dataSelected.push(data[item]);
            });

            handleFilterSearch(dataSelected);
    }, 300), [data, handleFilterSearch, formatDataSearch]);

    const pressEnterSearch = (e: KeyboardEvent<HTMLDivElement>): void => {
        if (e.key === 'Enter') {
            handleChangeFilter(searchBarContext?.search);
        }
    };

    function handleSearch(e: any) {
        searchBarContext?.setSearch(e.target.value);
        debouncedHandleSearch(e.target.value);
    }

    // Si rolesSection est un string qui est égal à un des strings de menu alors on met la variable isMenu à true
    const isMenu: boolean = (
        rolesSection === "accueil" ||
        rolesSection === "administration" ||
        rolesSection === "consolidation" ||
        rolesSection === "bibliotheque" ||
        rolesSection === "etat_comptable" ||
        rolesSection === "effectif_traite" ||
        rolesSection === "etude" ||
        rolesSection === "evaluation" ||
        rolesSection === "resultat" ||
        rolesSection === "salarie" ||
        rolesSection === "supervision" ||
        rolesSection === "consolidation_etat_comptable" ||
        rolesSection === "simulation"
    );

    return (
        <>
            <div className={'flex flex-col w-[50px] h-auto align-middle space-y-3 bg-[#4A5BCB] p-2 rounded-l-lg'}>
                {handleFilterSearch !== undefined && (
                    <Tooltip title={!disableSearch ? "Rechercher" : ""} placement="top">
                        <button
                            className={`p-1 mt-[5px] ${!disableSearch ? "text-white" : "text-gray-400"} ${!disableSearch ? "hover:text-MAIN_BLUE hover:bg-white hover:rounded-full" : ""}`}
                            onClick={handleOpenSearch}
                            disabled={disableSearch}
                        >
                            <SearchIcon />
                        </button>
                    </Tooltip>
                )}

                {handleDuplicate !== undefined && (
                    <Tooltip title="Duplication" placement="right">
                        <button
                            className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                            onClick={handleOpenDuplicate}
                        >
                            <DuplicateIcon />
                        </button>
                    </Tooltip>
                )}

                <AccessControl scope={isMenu ? SCOPE_WINDOW : SCOPE_TABLE} section={rolesSection} right={EXPORT}>
                    <Tooltip title="Exporter" placement="right">
                        <button
                            className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                            onClick={handleOpenExport}
                        >
                            <ExportIcon />
                        </button>
                    </Tooltip>
                </AccessControl>

                <Tooltip title="Copier" placement={"right"}>
                    <button
                        className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                        onClick={isDeleteDisable
                            ? () => setOpenDialogHelp({
                                title: "Impossible de Copier les éléments",
                                content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                open: true
                            })
                            : async () => {
                                const target: any[] = [];
                                let string: string = "";

                                select.forEach((item: any) => target.push(data.find((elem: {
                                    id: any;
                                }) => elem.id === item)));

                                target.forEach(item => {
                                    columns.forEach((col: any, index: number) => {
                                        if (!col.isExportable) {
                                            if (col.exportValue) {
                                                string += col.exportValue(item);
                                            } else {
                                                string += item[col.field];
                                            }
                                        }

                                        if (index !== columns.length - 1) {
                                            string += '\t';
                                        }
                                    });
                                    string += '\n';
                                });

                                await navigator.clipboard.writeText(string);

                                const message = select.length > 1 ? `${select.length} lignes ont été copiées` : "Une ligne a été copiée";
                                handleSuccess(message);
                                setAlertCopy(true);
                            }
                        }
                    >
                        <CopyIcon />
                    </button>
                </Tooltip>

                {handleOpenCreate !== undefined && (
                    <AccessControl scope={isMenu ? SCOPE_WINDOW : SCOPE_TABLE} section={rolesSection} right={ADD}>
                        <Tooltip title="Création" placement="right">
                            <button
                                className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                                onClick={handleOpenCreate}
                            >
                                <CreateIcon />
                            </button>
                        </Tooltip>
                    </AccessControl>
                )}

                {handleOpenDelete !== undefined && (
                    <AccessControl scope={isMenu ? SCOPE_WINDOW : SCOPE_TABLE} section={rolesSection} right={DELETE}>
                        <Tooltip title="Supprimer" placement="right">
                            <button
                                className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                                onClick={isDeleteDisable
                                    ? () => setOpenDialogHelp({
                                        title: "Suppression impossible",
                                        content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                        open: true
                                    })
                                    : handleOpenDelete
                                }
                            >
                                <DeleteIcon />
                            </button>
                        </Tooltip>
                    </AccessControl>
                )}

                {handleOpenRestore !== undefined && (
                    <Tooltip title="Restaurer" placement="right">
                        <button
                            className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                            onClick={isRestoreDisable
                                ? () => setOpenDialogHelp({
                                    title: "Restauration impossible",
                                    content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                    open: true
                                })
                                : handleOpenRestore
                            }
                        >
                            <RestoreIcon />
                        </button>
                    </Tooltip>
                )}

                {handleOpenReference !== undefined && (
                    <AccessControl scope={isMenu ? SCOPE_WINDOW : SCOPE_TABLE} section={rolesSection} right={ADD}>
                        <Tooltip title="Référencer" placement="right">
                            <button
                                className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE "
                                onClick={isReferenceDisable
                                    ? () => setOpenDialogHelp({
                                        title: "Verrouillage impossible",
                                        content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                        open: true
                                    })
                                    : handleOpenReference
                                }
                            >
                                <ReferenceIcon />
                            </button>
                        </Tooltip>
                    </AccessControl>
                )}

                {handleOpenLock !== undefined && (
                    <AccessControl scope={isMenu ? SCOPE_WINDOW : SCOPE_TABLE} section={rolesSection} right={ARCHIVING}>
                        <Tooltip title="Verrouiller" placement="right">
                            <button
                                className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                                onClick={isLockDisable
                                    ? () => setOpenDialogHelp({
                                        title: "Verrouillage impossible",
                                        content: "Veuillez sélectionner au moins un élément(s) du tableau",
                                        open: true
                                    })
                                    : handleOpenLock
                                }
                            >
                                <LockIcon />
                            </button>
                        </Tooltip>
                    </AccessControl>
                )}

                {handleOpenUpdate !== undefined && (
                    <AccessControl scope={isMenu ? SCOPE_WINDOW : SCOPE_TABLE} section={rolesSection} right={UPDATE}>
                        <Tooltip title="Modifier" placement="right">
                            <button
                                className="hover:bg-white hover:rounded-full p-1 text-white hover:text-MAIN_BLUE"
                                onClick={isUpdateDisable
                                    ? () => setOpenDialogHelp({
                                        title: "Modification impossible",
                                        content: "Veuillez sélectionner un élément du tableau",
                                        open: true
                                    })
                                    : handleOpenUpdate
                                }
                            >
                                <EditIcon />
                            </button>
                        </Tooltip>
                    </AccessControl>
                )}
            </div>

            <Popover
                open={Boolean(anchorElSearch)}
                anchorEl={anchorElSearch}
                onClose={handleCloseSearch}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={"fixed w-[500px] bg-white rounded-lg z-10"}>
                    <TextField
                        focused
                        value={searchBarContext?.search}
                        onChange={handleSearch}
                        onKeyDown={pressEnterSearch}
                        size={"small"}
                        InputProps={{
                            autoComplete: 'off',
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        />
                                    </svg>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <div className={"flex flex-row space-x-2"}>
                                    <button
                                        onClick={() => {
                                            searchBarContext?.setSearch("");
                                            handleFilterSearch(data);
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>

                                    <button onClick={handleCloseSearch}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            )
                        }}
                        variant={"outlined"}
                        sx={{width: "500px"}}
                        placeholder="Rechercher"
                    />
                </div>
            </Popover>

            <AlertsSnackBar
                handleClose={() => setAlertCopy(false)}
                open={alertCopy}
                message={"Une ligne a été copiée"}
                success={true}
            />

            <DialogHelp
                open={openDialogHelp.open}
                handleClose={() => setOpenDialogHelp({title: "", content: "", open: false})}
                title={openDialogHelp.title}
                content={openDialogHelp.content}
            />

            <DialogError
                open={error}
                handleClose={() => setError(false)}
                multiple
            />

            <DialogFilename
                formatDataWithoutSelect={formatDataWithoutSelect}
                type={openDialogFileName.type}
                open={openDialogFileName.open}
                handleClose={() => setOpenDialogFileName({open: false, type: "PDF"})}
                allData={data}
                data={formatData}
                origin={origin}
                exportUrl={exportUrl}
                filter={filter}
            />

            <Popover
                open={Boolean(anchorElExport)}
                anchorEl={anchorElExport}
                onClose={handleCloseExport}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List sx={{p: 0}}>
                    <ListItem disablePadding>
                        <ListItemButton
                            onClick={() => {
                                select.length > 0
                                    ? setOpenDialogFileName({open: true, type: "EXCEL"})
                                    : setError(true);
                            }}
                        >
                            <ListItemIcon>
                                <DescriptionIcon/>
                            </ListItemIcon>

                            <ListItemText primary="Excel avec les lignes sélectionnées"/>
                        </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                        <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "EXCEL_ALL"});}}>
                            <ListItemIcon>
                                <DescriptionIcon/>
                            </ListItemIcon>

                            <ListItemText primary="Excel avec toutes les données du tableau"/>
                        </ListItemButton>
                    </ListItem>

                    <Divider/>

                    {/*<ListItem disablePadding>*/}
                    {/*    <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "PDF"})}}>*/}
                    {/*        <ListItemIcon>*/}
                    {/*            <PictureAsPdfIcon/>*/}
                    {/*        </ListItemIcon>*/}

                    {/*        <ListItemText primary="PDF avec les lignes sélectionnées"/>*/}
                    {/*    </ListItemButton>*/}
                    {/*</ListItem>*/}

                    {/*<ListItem disablePadding>*/}
                    {/*    <ListItemButton onClick={() => { setOpenDialogFileName({open: true, type: "PDF_ALL"})}}>*/}
                    {/*        <ListItemIcon>*/}
                    {/*            <PictureAsPdfIcon/>*/}
                    {/*        </ListItemIcon>*/}

                    {/*        <ListItemText primary="PDF avec toutes les données du tableau"/>*/}
                    {/*    </ListItemButton>*/}
                    {/*</ListItem>*/}
                </List>
            </Popover>

            {/* @ts-ignore */}
            <Dialog component={'form'} onSubmit={handleSubmit} open={openDialogDuplication} onClose={handleCloseDuplication}>
                <DialogTitle>
                    Nom de la duplication
                </DialogTitle>

                <DialogContent>
                    <TextField
                        required
                        value={nameDuplication}
                        onChange={(event: any) => setNameDuplication(event.target.value)}
                        variant={"outlined"}
                        fullWidth
                        inputProps={{autoComplete: 'off'}}
                    />
                </DialogContent>

                <DialogActions>
                    <Button>
                        Annuler
                    </Button>

                    <LoadingButton loading={isLoadingDuplicate} type={"submit"} variant={'contained'}>
                        Duplicate
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
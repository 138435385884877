import React, { JSX, useEffect, useState, useCallback, useMemo } from "react";
import type { Perimeter } from "../../../../types";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Button, Chip, Paper, TextField } from "@mui/material";
import { useHandleError } from "../../HandleError/HandleError";
import * as request from "../../Utils/APIRequests/request";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import BoldName from "../../BoldName";
import type { FormatPerimeter } from "../../../Dashboard/Evaluation/Library/ToolsLibrary/PerimeterSelection";
import { useSelector } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface MultiAutocompleteUrlProps {
    handleChange(value: any[], globalActionSelect: boolean | null): any,
    value: any[] | undefined,
    label?: string,
    disabled?: boolean
    isRequired?: boolean,
    sx?: any | null,
    url: string,
    labelCode?: boolean, // show code instead of name ( like convention_collective = "0016" instead of "CCN de ..."
    labelCodeSociete?: boolean, // show code instead of name ( like convention_collective = "0016" instead of "CCN de ..."
    bold?: boolean // True si on veut afficher le code dans le nom
    reload?: boolean,
    perimeter?: FormatPerimeter,
    handleBlurPermimeter?: () => void,
}

/**
 * Function to draw <select> with autocomplete using MUI library
 *
 * @see https://mui.com/material-ui/react-autocomplete/
 *
 * @param handleChange - Function to handle change on option selection
 * @param value - Default value of the select
 * @param label - Label showed in the element
 * @param disabled - Parameter used to disable the element
 * @param isRequired - Parameter that define if the element must be filled
 * @param sx - CSS Style to apply to html elements used to render the select
 * @param url - URL to fetch for getting the data to render as <option>
 * @param labelCode - Parameter that define option render details
 * @param labelCodeSociete - Parameter that define option render details
 * @param bold - Parameter that define option render details (Render code)
 * @param reload - ?? Parameter seems no longer used ??
 * @param perimeter - The active perimeter currently used
 * @param handleBlurPermimeter - Function to handle the focus out of the element
 *
 * @returns {JSX.Element} - A <select> MUI Autocomplete element
 */
export default function MultiAutocompleteFilter({
    handleChange,
    value,
    label,
    disabled,
    isRequired,
    sx = { height: '40px' },
    url,
    labelCode,
    labelCodeSociete,
    bold = false,
    perimeter,
    handleBlurPermimeter,
}: MultiAutocompleteUrlProps): JSX.Element {
    const [data, setData] = useState<any[]>([]);
    const [isError, setIsError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const sizePerimeter = useSelector((state: any) => state.longerPerimeter);
    const handleError = useHandleError();
    const navigate = useNavigate();
    const navigateToDisconnect = useCallback(() => navigate("/dashboard/disconnect"), [navigate]);
    let clearIconClicked: boolean = false;

    function fetchShort(req: request.Request): void {
        if (req.isSuccessful) {
            const target: any[] = [];

            req.response.data.data.forEach((item: any): void => {
                let value: number | string;
                let label: string;

                if (labelCode) {
                    value = item.code || null;
                    label = `${item.code} - ${item.nom}`;
                } else if (labelCodeSociete) {
                    value = item.value || item.id;
                    label = bold ? `${item.code_societe} - ${item.nom}` : item.nom;
                } else {
                    value = item.value || item.id;
                    label = bold ? `${value} - ${item.nom}` : item.nom;
                }

                target.push({ ...item, value, label });
            });

            setData(target);
            setIsLoading(false);
        } else if (req.isSessionExpired) {
            handleError(req.error, (): void => {
                setIsLoading(false);

                navigateToDisconnect();
            }, req.error?.response?.data?.message ?? request.EXPIRED_SESSION_MESSAGE, true);
        } else {
            setIsError(true);
            setIsLoading(false);
        }
    }

    const reloadData = useCallback(async (): Promise<void> => {
        setIsError(false);
        setIsLoading(true);
        
        if (!isOpen) return;

        if (perimeter) {
            const req: request.Request = await request.post(url, {
                pole                    : label !== "Pôle"                     ? perimeter.pole.map(item => item.value)                     : [],
                division                : label !== "Division"                 ? perimeter.division.map(item => item.value)                 : [],
                direction_operationnelle: label !== "Direction opérationnelle" ? perimeter.direction_operationnelle.map(item => item.value) : [],
                entite_juridique        : label !== "Entité juridique"         ? perimeter.entite_juridique.map(item => item.value)         : [],
                id_import               : perimeter.id_import?.value ?? null,
                date_import             : perimeter.date_import,
            } as Perimeter);

            fetchShort(req);
        } else {
            const req: request.Request = await request.get(url);

            fetchShort(req);
        }
        // surtout pas rajouter de dépendance ici, sinon ça rechargera à chaque fois que la dépendance change
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect((): void => {
        // If the autocomplete is closed or data is already loaded, don't reload (prevent request each time the user open the autocomplete)
        if (!isOpen) return;

        reloadData();
    }, [reloadData, isOpen]);

    const handleOpenClose = () => setIsOpen(!isOpen);

    const icon:JSX.Element = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon:JSX.Element = <CheckBoxIcon fontSize="small" />;

    // Do not update array of dependencies for avoid scroll reset on value selection
    const paperComponent = useCallback(({children, ...other}: any): JSX.Element => {
        const handleSelectAll = (event: React.MouseEvent): void => {
            event.stopPropagation();

            // Ignore Eslint warning on value cause the value is updated on focus out
            // eslint-disable-next-line
            value = data;
            handleChange(data, true);
        };

        const handleDeselectAll = (event: React.MouseEvent): void => {
            event.stopPropagation();

            value = [];
            handleChange([], false);
        };

        return(
            <Paper {...other}>
                <Box
                    sx={{display: 'flex', justifyContent: 'space-between', p: 1}}
                    onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => event.preventDefault()}
                >
                    {value && value.length > 0
                        ? <Button size="small" onClick={handleDeselectAll}>Tout désélectionner</Button>
                        : <Button size="small" onClick={handleSelectAll}>Tout sélectionner</Button>
                    }
                </Box>

                {children}
            </Paper>
        );
    }, [data, clearIconClicked]);

    const memoPaperComponent = useMemo(() => (paperComponent), [paperComponent]);

    if (isError) return (
        <Button
            sx={{ border: 1, borderRadius: 0, borderColor: "gray" }}
            className={"flex w-full justify-center"}
            onClick={reloadData}
        >
            Cliquer pour recharger
        </Button>
    );

    return(
        <Autocomplete
            // Default props
            disableCloseOnSelect
            multiple
            autoHighlight
            handleHomeEndKeys
            fullWidth
            // CSS
            sx={{sx, input: {background: "bg-MAIN_COLOR"}}}
            className={`pb-10 ${isOpen ? "" : "w-full"}`}
            ListboxProps={{ style: { maxHeight: 400, overflow: 'auto' }}}
            // Wording
            noOptionsText={"Aucun résultat"}
            openText={"Ouvrir"}
            closeText={"Fermer"}
            clearText={"Effacer"}
            loadingText={"Chargement..."}
            size={"small"}
            // Others
            value={value}
            disabled={disabled}
            open={isOpen}
            loading={isLoading}
            options={isLoading ? [] : data}
            disableClearable={isOpen}
            // Events
            onBlur={handleBlurPermimeter}
            onOpen={handleOpenClose}
            onClose={handleOpenClose}
            onChange={(_event: React.SyntheticEvent, val: any[]): void => {
                if (clearIconClicked) {
                    value = [];

                    handleChange([], false);
                } else handleChange(val, null);

                clearIconClicked = false;
            }}
            // Render
            limitTags={1}
            clearIcon={
                <XMarkIcon
                    className="h-6 w-6 text-gray-500"
                    onClick={(event: React.MouseEvent<SVGSVGElement>): void => {
                        event.preventDefault();

                        clearIconClicked = true;
                    }}
                />
            }
            renderTags={(value, getTagProps): JSX.Element | JSX.Element[] => (isOpen
                ? (
                    <div className="max-h-[35px] w-full overflow-auto max-w-[200px]">
                        {value.map((option, index) => (
                            <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
                        ))}
                    </div>
                )
                : (
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option.label} style={{maxWidth: "200px"}} {...getTagProps({ index })} />
                    ))
                )
            )}
            isOptionEqualToValue={(option: any, value: any): boolean => option.value === value.value}
            PaperComponent={memoPaperComponent}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={props.id}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 15, padding : 0 }}
                        checked={selected}
                    />

                    <BoldName
                        name={option.label}
                        regex={bold
                            ? /(.+?) - /
                            : undefined
                        }
                    />
                </li>
            )}
            renderInput={(params) => (
                <div
                    style={{ zIndex: 1000, width: window.innerWidth / 5, maxHeight: window.innerHeight / 1.13 + (!sizePerimeter ? 40 : 0) }}
                    className={"overflow-auto align-top bg-MAIN_COLOR absolute pt-1.5"}
                >
                    <TextField
                        {...params}
                        label={label}
                        required={isRequired ? value?.length === 0 : false}
                        InputLabelProps={{required: false}}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off'
                        }}
                    />
                </div>
            )}
        />
    );
}
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Snackbar } from "@mui/material";
import InfoIcon from "../../Icons/InfoIcon";

const HandleErrorContext = React.createContext((err: any, callback: any, message: string = "Une erreur s'est produite.", needRedirect: boolean = false) => {
});

export default function HandleError({children}: any) {
    const [errorMessage, setErrorMessage] = useState<string|string[]>("Une erreur s'est produite.");
    const [openError, setOpenError] = useState(false);
    const navigate = useNavigate();

    const handleError = useCallback((err: any, callback: any, message: string = "Une erreur s'est produite.", needRedirect: boolean = false) => {
        if (axios.isCancel(err))
            return;
        setErrorMessage(message ? message : "Une erreur s'est produite.");
        setOpenError(true);

        if (needRedirect)
            navigate('./error');
        if (!!callback)
            callback();
    }, [navigate]);

    return <HandleErrorContext.Provider value={handleError}>
        {children}
        {!!errorMessage && <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} autoHideDuration={6000} open={openError}  onClose={() => {
            setOpenError(false);
            setErrorMessage('');
        }}>
            <div className="flex p-4 mb-4 gap-2 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                <InfoIcon />

                <span className="sr-only">Info</span>

                <div>
                    { typeof errorMessage === "object"
                        ? errorMessage.map((err, index) => <p key={index}>{err}</p>)
                        : errorMessage}
                </div>
            </div>
        </Snackbar>}
    </HandleErrorContext.Provider>;
}

export const useHandleError = () => React.useContext(HandleErrorContext);

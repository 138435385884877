import React, { JSX } from "react";

export default function PowerIcon(): JSX.Element {
    return (
        <svg
            xmlns={"http://www.w3.org/2000/svg"}
            fill={"none"}
            viewBox={"0 0 24 24"}
            strokeWidth={1.8}
            stroke={"currentColor"}
            className={"w-6 h-6 text-white"}
        >
            <path
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                d={"M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"}
            />
        </svg>
    );
}
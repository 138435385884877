import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { unixTimestampToFrFormat } from "../../Utils/utils";

export interface EvaluationDetails {
    evaluationName: string,
    evaluationReference: string,
    evaluationStudy: string,
    calculDate: number,
    calculType: string,
    calculMethod?: string,
    creationDate: number,
    financialCommitmentType?: string,
    endContractDate?: number
}

export interface FinancialParamValues {
    id: number,
    date_creation: number,
    createur: string,
    nom: string
}

export interface FinancialParam {
    label: string,
    data: FinancialParamValues[]
}

export interface FinancialsParameters {
    prestation_indemnite_fin_carriere: FinancialParam,
    prestation_medaille: FinancialParam,
    profil_carriere_age: FinancialParam,
    profil_carriere_anc: FinancialParam,
    survie_femmes: FinancialParam,
    survie_hommes: FinancialParam,
    taux_actualisation: FinancialParam,
    taux_charges_patronales: FinancialParam,
    taux_inflation: FinancialParam,
    turnover_age: FinancialParam,
    turnover_anc: FinancialParam,
}

export default async function exportPDF(data: any, fileName: string) {
    (pdfMake as any).vfs = window.pdfMake.vfs;
    const header: any[] = [];
    const newFormat: any[][] = [];

    for (const key in data[0]) {
        header.push({text: key, fontSize: 7, bold: true});
    }
    newFormat.push(header);
    data.forEach((elem: any) => {
        const row: any[] = [];
        for (const i in elem) {
            row.push({text: elem[i], fontSize: 6});
        }
        newFormat.push(row);
    });

    const docDefinition: any = {
        pageOrientation: 'landscape',
        pageMargins: [ 10, 10, 10, 20 ],
        footer: function (currentPage: { toString: () => string; }, pageCount: string) {
            return { text: "Page " + currentPage.toString() + ' sur ' + pageCount, alignment: 'right', style: 'normalText', fontSize: 8, margin: [0, 0, 10, 10] };
        },
        content: [
            {text: `Liste des ${fileName}(s)`, fontSize: 16, margin: [ 0, 0, 0, 10 ]},
            {
                table: {
                    body: newFormat,
                },
                layout: {
                    fillColor: function (rowIndex: number, node: any, columnIndex: any) {
                        return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
                    }
                }
            },
        ]
    };

    pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
}

export async function exportEvaluationDetailsPDF(
    evaluationDetails: EvaluationDetails,
    financialsParameters: FinancialsParameters,
    fileName: string
): Promise<void> {
    (pdfMake as any).vfs = window.pdfMake.vfs;

    const newFormat: any[][] = [];

    Object.values(financialsParameters).forEach((financialParam: FinancialParam): void => {
        const valuesText: string = financialParam.data.map((value: FinancialParamValues): string => `${value.nom} - ${unixTimestampToFrFormat(value.date_creation)}`).join('\n');

        newFormat.push([
            { text: financialParam.label, fontSize: 10, bold: true },
            { text: valuesText || 'Aucune donnée', fontSize: 10 }
        ]);
    });

    const docDefinition: any = {
        pageOrientation: 'landscape',
        pageMargins: [10, 10, 10, 20],
        footer: (currentPage: { toString: () => string }, pageCount: string): any => ({
            text: `Page ${currentPage.toString()} sur ${pageCount}`,
            alignment: 'right',
            style: 'normalText',
            fontSize: 8,
            margin: [0, 0, 10, 10],
        }),
        content: [
            {
                text: `Détails de l'évaluation: ${evaluationDetails.evaluationName}`,
                fontSize: 16,
                margin: [0, 0, 0, 10],
            },
            {
                text: [
                    { text: "Nom de l'évaluation: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.evaluationName, fontSize: 10 },
                ],
            },
            {
                text: [
                    { text: "Nom de l'évaluation de référence: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.evaluationReference ?? "Non renseigné", fontSize: 10 }
                ],
            },
            {
                text: [
                    { text: "Nom de l'étude utilisée: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.evaluationStudy, fontSize: 10 }
                ],
            },
            {
                text: [
                    { text: "Date de calcul de l'évaluation: ", fontSize: 12, bold: true },
                    { text: unixTimestampToFrFormat(evaluationDetails.calculDate), fontSize: 10 }
                ],
            },
            {
                text: [
                    { text: "Type de calcul: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.calculType ?? "Non renseigné", fontSize: 10 }
                ],
            },
            {
                text: [
                    { text: "Methode de calcul de l'évaluation: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.calculMethod ?? "Non renseigné", fontSize: 10 }
                ],
            },
            {
                text: [
                    { text: "Date de création: ", fontSize: 12, bold: true },
                    { text: unixTimestampToFrFormat(evaluationDetails.creationDate), fontSize: 10 }
                ],
            },
            {
                text: [
                    { text: "Type d'échancier: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.financialCommitmentType ?? "Non renseigné", fontSize: 10 }
                ]
            },            {
                text: [
                    { text: "Date de fin de contrat: ", fontSize: 12, bold: true },
                    { text: evaluationDetails.endContractDate ?? "Non renseigné", fontSize: 10 }
                ]
            },
            {
                canvas: [{
                    type: 'line',
                    x1: 0,
                    y1: 0,
                    x2: 820,
                    y2: 0,
                    lineWidth: 1,
                    lineColor: '#E5E5E5',
                }],
                margin: [0, 10, 0, 10],
            },
            {
                text: "Paramètres de l'étude",
                fontSize: 12,
                alignment: "center",
                bold: true
            },
            {
                table: {
                    widths: ['25%', '75%'],
                    body: newFormat,
                },
                layout: {
                    fillColor: (rowIndex: number): string | null => rowIndex % 2 === 0 ? '#CCCCCC' : null
                },
                margin: [0, 10, 0, 10],
            },
        ],
    };

    pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
}
